import React from 'react'
import PaginaPrincipal from '../paginas/principal/index'

// grupo
import CompliancePagina from '../paginas/ogrupo/compliance'
import CustomerExperiencePagina  from '../paginas/ogrupo/customerexperience'
import GestaoDePessoasPagina from '../paginas/ogrupo/gestaodepessoas'
import ParceirosPagina from '../paginas/ogrupo/parceiros'
import SobrePagina from '../paginas/ogrupo/sobre' 
import UnidadesPagina from '../paginas/ogrupo/unidades'


// carreira
import CarreiraPagina from '../paginas/carreira/carreira';
import MvvPagina from '../paginas/carreira/mvv';

// blog
import BlogPagina from '../paginas/blog/blog';
// treinamneto
import TreinamentoPagina from '../paginas/treinamento/treinamento';
// Contato
import CanaisContatoPagina from '../paginas/contato/canaisdecontato';
import FormularioContatoPagina from '../paginas/contato/formulariocontato';

// pdpPagina
import PdpPagina from '../paginas/politicas/politica-de-privacidade/index';
import PrivacidadeSIPagina from '../paginas/politicas/privacidade-e-si/index';
import { createHashRouter } from 'react-router-dom';
import Programadeintegridade from '../paginas/ogrupo/programaintegridade'
import Sustentabilidade from '../paginas/ogrupo/sustentabilidade'
import CodigoEtica from '../paginas/ogrupo/codigoetica'
import TermosdeUsoPagina from '../paginas/politicas/termos-de-uso'
import DireitosHumanos from '../paginas/ogrupo/politicasdireitoshumanos'
import RelatorioIgualdade1 from '../paginas/ogrupo/relatorio-igualdade-salarial-1'
import RelatorioIgualdade2 from '../paginas/ogrupo/relatorio-igualdade-salarial-2'




 const routes = createHashRouter([
    { 
    path: '',
    element: <PaginaPrincipal />,
    },
    {
        path: '/',
        titulo: 'O grupo',
        children: [
            {
                path: '/ogrupo',
                titulo: 'Sobre a aval',
                element: <SobrePagina />
            },
            {
                path: '/ogrupo/filiais',
                titulo: 'Unidades',
                element: <UnidadesPagina />
            },
            {
                path: '/ogrupo/parceiros',
                titulo: 'Parceiros',
                element: <ParceirosPagina />
            },
            {
                path: '/ogrupo/gestaodepessoas',
                titulo: 'Gestão de pessoas',
                element: <GestaoDePessoasPagina />
            },
            {
                path: '/ogrupo/compliance',
                titulo: 'Compliance',
                element: <CompliancePagina />
            },
            {
                path: '/ogrupo/customerexperience',
                titulo: 'Customer Experience',
                element: <CustomerExperiencePagina />
            },
            {
                path: '/ogrupo/codigo-de-etica',
                titulo: 'Código de Ética',
                element: <CodigoEtica />

              },
              {
                path: '/ogrupo/programa-de-integridade',
                titulo: 'Programa de integridade',
                element: <Programadeintegridade />

              },
              {
                path: '/ogrupo/relatorio-de-sustentabilidade',
                titulo: 'Relatório de Sustentabilidade',
                element: < Sustentabilidade/>

              },
              {
                path: '/ogrupo/politicas-direitos-humanos',
                titulo: 'Políticas de Direitos Humanos',
                element: <DireitosHumanos />
              },
              {
                path: '/ogrupo/relatorio-igualdade-salarial-1',
                titulo: 'Relatório de transparência e igualdade salarial 1º sem',
                element: <RelatorioIgualdade1 />
              },
              {
                path: '/ogrupo/relatorio-igualdade-salarial-2',
                titulo: 'Relatório de transparência e igualdade salarial 2º sem',
                element: <RelatorioIgualdade2 />
              },
         
        ]
    },

    {
        path: '/',
        titulo: 'Politicas',
        children: [
            {
                path: '/politica/seguranca-da-informacao',
                titulo: 'Segurança da informação',
                element: <PrivacidadeSIPagina />
            },
            {
                path: '/politica/politica-de-privacidade',
                titulo: 'Politica de privacidade',
                element: <PdpPagina />
            },
            {
                path: '/politica/termos-de-uso',
                titulo: 'Termos de uso',
                element: <TermosdeUsoPagina/>
            },
         
        ]
    },
    {
        nav: true,
        path: '/',
        titulo: 'Carreira',
        children: [
            {
                path: 'carreira/missaovisaoevalores',
                titulo: 'Missão visão e valores',
                element: <MvvPagina />
        
            },
            {
                path: 'carreira',
                titulo: 'Carreira',
                element: <CarreiraPagina />

            }
        ]
        
    },
    {
        path: '/blog',
        titulo: 'Blog',
        element: <BlogPagina />,

    },{
        nav: true,
        path: '/treinamento',
        titulo: 'Treinamento',
        element: <TreinamentoPagina />,},

    
    {
        titulo: 'Fale conosco',
        path: '/',
        children: [
            {
                titulo: 'Canais de Contato ',
                path: '/contato/canais',
                element: <CanaisContatoPagina />,
        
            },
            {
                titulo: 'Fale conosco',
                path: '/contato',
                element: <FormularioContatoPagina />,

            }
        ]
        
    }

])
export default routes;