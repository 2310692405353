import axios from 'axios';
import React, { memo, useState } from 'react';
import { findAllInRenderedTree } from 'react-dom/test-utils';
import { useForm } from 'react-hook-form';
import { Pagina } from '../../componentes/pagina';
import './formulariocontato.sass'
import Template from './template';
export default memo(function FormularioContatoPagina() {
const { handleSubmit, reset, register,watch,formState
} = useForm();

const [envio,setEnvio] = useState(false);
function onSubmit(form) {
  enviar(form);
}
const { isDirty, isValid } = formState;
 async function enviar(formulario) {
  
      // manipula os dados para enviar via api
      var t = this;
      const layout = await Template(formulario);
      let form_data = new FormData();
      form_data.append("Assunto", "Contato Grupo Aval")
      form_data.append("Mensagem", layout)
      form_data.append("Origem", "Site Grupo Aval")
    switch (formulario.banco) {
      case 'Mercado Pago':
        {
          console.log('Mercado Pago');
          form_data.append("Destino", "gestores.mp@grupoaval.com.br")
        }
        break;
      case 'Banco Bradesco S/A':
        {
          console.log('Banco Bradesco S/A');
          form_data.append("Destino", "gestores.bradesco@grupoaval.com.br")
        }
        break;
      case 'Banco Itaú Unibanco S/A':
        {
          console.log('Banco Itaú Unibanco S/A');
          form_data.append("Destino", "gestores.Itau@grupoaval.com.br")
        }
        break;
      case 'Banco Pan S/A':
        {
          console.log('Banco Pan S/A');
          form_data.append("Destino", "gestao.pan@grupoaval.com.br")
        }
        break;
      case 'Ativos S.A':
        {
          console.log('Ativos S.A');
          form_data.append("Destino", "gestores.Ativos@grupoaval.com.br")
        }
        break;
      default:
              console.log('Outros')
              form_data.append("Destino", "henrique.neves@toledopizaadvogados.com.br")


    }
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
 axios({
      method: "post",
      url: "https://smtpemail.grupoaval.com.br:1001/api/email",
      data: form_data,
   }).then(Response => {
      console.log(Response.data);
      setEnvio(true);
      setTimeout(() => {
        setEnvio(false);
        reset();
      },2000)
      alert("enviado com sucesso")
    }).catch(error => alert("Ocorreu um erro ao enviar o e-mail"))
    
  }

  return  <Pagina titulo={"Fale conosco"}> 
  
  <div class="ui container conteudo contato">
      <div class="ui stackable two column grid">
        <div class="five wide column">
          <div class="contato msg">Preencha o formulário de contato com as informações solicitadas.</div>
          <div class="p-2">
            <h4 class="ui header">
              Ouvidoria
              <p
                class="sub header"
              >Se você tiver sugestão, crítica e elogio referente à prestação de nossos serviços ou reclamação não solucionada pelos outros Canais de Relacionamento, entre em contato com nossa Ouvidoria.</p>
            </h4>
            <a href="#">ouvidoria@grupoaval.com.br</a>
          </div>
        </div>
        <div class="eleven wide column contato">
          <h3>Fale conosco</h3>
  {
    envio && <>
        <div class="ui success message" >
        <p>Mensagem enviada com sucesso</p>
        </div>
    </>
  }
    
          <form class="ui form container" onSubmit={handleSubmit(onSubmit)}>
            <div class="field">
              <div class="two fields">
                <div class="field">
                  <label>Nome</label>
                  <input type="text" name placeholder="Nome" {...register("nome",{ required: true})}/>
                </div>
                <div class="field">
                  <label>Empresa</label>
                  <input type="text" name placeholder="Empresa"  {...register("empresa",{ required: false})}/>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields two">
                <div class="field">
                  <label>Telefone</label>
                  
                  <input type="text" placeholder="(11) 9999-9999" {...register("telefone",{ required: true})} 
/>
                </div>
                <div class="field">
                  <label>Email</label>
                  <input
                    type="text"
                    {...register("email")} 
                    name
                    placeholder="exemplo@grupoaval.com.br"/>
                </div>
              </div>
            </div>
            <div class="fields">
              <div class="field wide eight">
                <label>Assunto</label>
                <input type="text" name  {...register("assunto",{ required: true})} />
              </div>
              <div class="field wide eight">
                <label>Banco</label>
                <select {...register("banco")}>
                  <option value=""></option>
                  <option value="Mercado Pago">Mercado Pago</option>
                  <option value="Banco Bradesco S/A">Banco Bradesco S/A</option>
                  <option value="Banco Itaú Unibanco S/A">Banco Itaú Unibanco S/A</option>
                  <option value="Banco Pan S/A">Banco Pan S/A</option>
                  <option value="Ativos S.A">Ativos S.A</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label>Messagem</label>
              <textarea {...register("messagem",{ required: true})}>  </textarea>
            </div>

          <div className='field'>
          <div class="ui checkbox" >
  <input type="checkbox" name="example" {...register("termos")}/>
  <label>Autorizar o recebimento de e-mail e sms!</label>
</div>
          </div>

            {/* <div class="ui button orange" tabindex="0"  v-if="!envio">Enviar</div> */}
            <button class="ui button" tabindex="0" onClick={() => {reset()}}  >Limpar</button>
            <button  class="ui button orange " onClick={() => {enviar()}} disabled={!isDirty || !isValid}>Enviar</button>
        
          </form> 
        </div>
      </div>
    </div>
  
  </Pagina>;
  });
  